import { customerWebsiteRoutes } from '@router/routes';
import { Divider, Radio, RadioChangeEvent, Space, Typography } from 'antd';
import { FC, Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';

const { Paragraph } = Typography;
const { shop } = customerWebsiteRoutes;

import { LocalStorageCart } from '@/types';
import styles from '../ShopSelector.module.scss';

type Props = {
  businessLocations: BusinessLocationFragment[];
  showModal: (location: string) => void;
  onCloseDrawer?: () => void;
};

const LocationSelector: FC<Props> = ({
  businessLocations,
  onCloseDrawer,
  showModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useLocalStorage('location', '');
  const [, setLocalStorageLocation] = useState(currentLocation);
  const [localStorageType] = useLocalStorage('type', '');
  const [localStorageCart, setLocalStorageCart] = useLocalStorage(
    'cart',
    [] as LocalStorageCart,
  );

  const onLocationChange = (e: RadioChangeEvent) => {
    const newLocation = e.target.value;

    if (onCloseDrawer) {
      onCloseDrawer();
    }

    if (localStorageCart.length > 0) {
      return showModal(newLocation);
    }

    setCurrentLocation(newLocation);
    setLocalStorageLocation(newLocation);

    setLocalStorageCart([]);
    localStorage.removeItem('order');

    const isShopPage = location.pathname.includes('shop');
    const isCheckoutPage = location.pathname.includes('checkout');
    if (isShopPage || isCheckoutPage) {
      navigate(`${shop}/${newLocation}/${localStorageType}`);
    }
  };

  return (
    <Radio.Group
      onChange={onLocationChange}
      value={currentLocation}
      className={styles.shopItemsSortOptions}
    >
      <Space direction={'vertical'}>
        {businessLocations.map((businessLocation, index) => (
          <Fragment key={index}>
            <Radio
              value={businessLocation.id}
              className={styles.shopItemsSortOption}
            >
              <Paragraph className={styles.menuItemLocationName}>
                {businessLocation?.attributes?.name}
              </Paragraph>
              <Paragraph
                className={styles.menuItemLocationAddress}
                ellipsis={{ rows: 2 }}
              >
                {
                  businessLocation?.attributes?.location?.data?.attributes
                    ?.address
                }
              </Paragraph>
            </Radio>
            {index < businessLocations.length - 1 && (
              <Divider style={{ margin: '8px 0' }} />
            )}
          </Fragment>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default LocationSelector;
