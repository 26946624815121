import { LocalStorageCart, LocalStorageOrder } from '@/types';
import { useOrderData } from '@hooks/useOrderData';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export function useLocalOrderExistenceCheck(): void {
  const [localStorageOrder] = useLocalStorage('order', {} as LocalStorageOrder);
  const [, setLocalStorageCart] = useLocalStorage(
    'cart',
    [] as LocalStorageCart,
  );
  const { order, orderLoading } = useOrderData(localStorageOrder?.id);

  useEffect(() => {
    if (localStorageOrder?.id && !orderLoading && !order) {
      setLocalStorageCart([]);
      localStorage.removeItem('order');
    }
  }, [localStorageOrder, orderLoading, order, setLocalStorageCart]);
}
