import { AliasToken } from 'antd/es/theme/interface';

export const typography: Partial<AliasToken> = {
  fontFamily: 'Jost, Inter, sans-serif',
  fontSizeHeading1: 32,
  lineHeightHeading1: 1.25,
  fontSizeHeading2: 24,
  lineHeightHeading2: 1.5,
  fontSizeHeading3: 20,
  lineHeightHeading3: 1.25,
  fontSizeHeading4: 14,
  fontWeightStrong: 500,
};
