import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export const useStorageLocation = (): string | null => {
  const [locationFromStorage] = useLocalStorage('location', '');

  return locationFromStorage;
};

export const useStorageType = (): string | null => {
  const [type, setType] = useState<string | null>('');
  const [typeFromStorage, setTypeFromStorage] = useLocalStorage('type', '');

  useEffect(() => {
    if (typeFromStorage) {
      setType(typeFromStorage);
    } else {
      setType('products');
      setTypeFromStorage('products');
    }
  }, [typeFromStorage, setTypeFromStorage]);

  return type;
};
