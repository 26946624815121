import CheckoutStatusPage from '@pages/website/checkout/checkout-status-page/CheckoutStatusPage';

const CheckoutErrorPage = () => {
  return (
    <CheckoutStatusPage
      checkoutStatus={'error'}
      checkoutTitle={'Payment failed'}
      checkoutSubtitle={
        'Your payment was not successful. Please try again or contact us for further assistance.'
      }
    />
  );
};

export default CheckoutErrorPage;
