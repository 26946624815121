import { Icon } from '@assets/icon';
import CustomContainer from '@components/layout/MainLayout/custom-container/CustomContainer';
import FooterContacts from '@components/layout/MainLayout/footer/footer-contacts/FooterContacts';
import FooterWorkingHours from '@components/layout/MainLayout/footer/footer-working-hours/FooterWorkingHours';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Col, Collapse, Row, Typography } from 'antd';
import React, { FC, useMemo } from 'react';
import styles from './Footer.module.scss';
import FooterMainText from './footer-main-text/FooterMainText';
import FooterPageLinks from './footer-page-links/FooterPageLinks';
import SubFooter from './sub-footer/SubFooter';

const { Text } = Typography;

const panelStyle: React.CSSProperties = {
  background: 'none',
  border: 'none',
};

const Footer: FC<{ data: WebsiteEntity; loading: boolean }> = ({
  data,
  loading,
}) => {
  const { md } = useBreakpoint();
  const logo = data?.attributes?.initialSettings?.image?.find(
    (it) => it?.title === 'logo',
  );
  const followUsLinks = data?.attributes?.footer?.button as ComponentUiLink[];
  const nameOfCompany = data?.attributes?.tenant?.data?.attributes?.companyName;
  const footerContacts = data?.attributes?.websiteContacts;
  const footerWorkingHours = data?.attributes?.workingHours;

  const getItems = useMemo(
    () => [
      {
        key: '1',
        label: (
          <Text strong className={styles.linkTitle}>
            {'Company'}
          </Text>
        ),
        children: <FooterPageLinks />,
        style: panelStyle,
      },
      {
        key: '2',
        label: (
          <Text strong className={styles.linkTitle}>
            {'Working Hours'}
          </Text>
        ),
        children: (
          <FooterWorkingHours
            footerWorkingHoursData={footerWorkingHours}
            workingHoursLoading={loading}
          />
        ),
        style: panelStyle,
      },
      {
        key: '3',
        label: (
          <Text strong className={styles.linkTitle}>
            {'Contact Us'}
          </Text>
        ),
        children: (
          <FooterContacts
            footerContactsData={footerContacts}
            contactsLoading={loading}
          />
        ),
        style: panelStyle,
      },
    ],
    [footerContacts, footerWorkingHours, loading],
  );

  return (
    <footer className={styles.footerSectionWrapper}>
      <CustomContainer>
        <Row className={styles.content}>
          <FooterMainText
            footerLogo={logo?.media?.data?.attributes?.url}
            footerText={data?.attributes?.footer?.heading?.title}
            footerLoading={loading}
          />
          {md ? (
            <Col span={24} xl={14} className={styles.linkBlock}>
              <Row gutter={24}>
                {getItems.map((item) => (
                  <Col span={8} key={item.key}>
                    {item.label}
                    {item.children}
                  </Col>
                ))}
              </Row>
            </Col>
          ) : (
            <Collapse
              items={getItems}
              className={styles.linkBlock}
              defaultActiveKey={['0']}
              bordered={false}
              expandIconPosition={'end'}
              expandIcon={({ isActive }) => {
                return (
                  <Icon
                    type={isActive ? 'arrow-up-collapse' : 'arrow-down'}
                    exactSize={24}
                  />
                );
              }}
            />
          )}
        </Row>
        <SubFooter
          companyName={nameOfCompany}
          followUsLinksData={followUsLinks}
          linksLoading={loading}
        />
      </CustomContainer>
    </footer>
  );
};

export { Footer };
