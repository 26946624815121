import FooterLegalAgreements from '@components/layout/MainLayout/footer/footer-legal-agreements/FooterLegalAgreements';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';
import styles from '../Footer.module.scss';
import FooterFollowLinks from '../footer-follow-links/FooterFollowLinks';
const { Text } = Typography;

type Props = {
  companyName: Maybe<string>;
  followUsLinksData: ComponentUiLink[];
  linksLoading?: boolean;
};

const SubFooter: FC<Props> = ({
  companyName,
  followUsLinksData,
  linksLoading,
}) => {
  const currentYear = new Date().getFullYear();

  return (
    <Row className={styles.subFooter} gutter={[24, 24]}>
      <Col span={24} md={12} className={styles.linkBlock}>
        <FooterLegalAgreements />
      </Col>
      <Col span={24} md={12}>
        <FooterFollowLinks
          followUsLinksArray={followUsLinksData}
          linksLoading={linksLoading}
        />
      </Col>
      <Col span={24} className={styles.copyrightWrapper}>
        <Text
          className={styles.copyrightText}
        >{`© ${currentYear} ${companyName}, Inc. All rights reserved.`}</Text>
      </Col>
    </Row>
  );
};

export default SubFooter;
