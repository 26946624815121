import ImagePlaceholder from '@ui/image-placeholder/ImagePlaceholder';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import styles from './ItemImage.module.scss';

type Props = {
  imageData?: UploadFileEntity;
  multipleImagesData?: ProductEntity[];
  wrapperClass?: string;
};
const ItemImage: FC<Props> = ({
  imageData,
  multipleImagesData,
  wrapperClass,
}) => {
  return (
    <div className={classNames(styles.wrapper, wrapperClass)}>
      {multipleImagesData ? (
        <>
          {multipleImagesData?.slice(0, 4).map((product, index) =>
            product?.attributes?.files?.data?.[0]?.attributes?.url ? (
              <img
                key={index}
                src={product?.attributes?.files?.data?.[0]?.attributes?.url}
                alt={
                  product?.attributes?.files?.data?.[0]?.attributes
                    ?.alternativeText ?? 'shop-item-image'
                }
                className={styles.oneOfImages}
              />
            ) : (
              <Fragment key={index}>
                <ImagePlaceholder />
              </Fragment>
            ),
          )}
        </>
      ) : (
        <>
          {imageData ? (
            <img
              src={imageData?.attributes?.url}
              alt={imageData?.attributes?.alternativeText ?? 'shop-item-image'}
            />
          ) : (
            <ImagePlaceholder />
          )}
        </>
      )}
    </div>
  );
};

export default ItemImage;
