import { useMemo } from 'react';

import {
  useUpdateClassOrderItemMutation,
  useUpdateCompositeProductOrderItemMutation,
  useUpdateMembershipOrderItemMutation,
  useUpdateProductOrderItemMutation,
  useUpdateServiceOrderItemMutation,
} from '@/graphql';

import { LocalStorageCart } from '@/types';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useLocalStorage } from 'usehooks-ts';

export type OrderItemTypeMap =
  | 'products'
  | 'composite-products'
  | 'services'
  | 'memberships'
  | 'classes';

export function useUpdateOrderItem<T extends OrderItemTypeMap>(itemType: T) {
  const [, setLocalStorageCart] = useLocalStorage(
    'cart',
    [] as LocalStorageCart,
  );
  const message = useStatusMessage();

  const setUpdatedActiveItems = (
    updateProductId: string,
    updateProductQuantity: number,
  ) => {
    setLocalStorageCart((prevCart) => {
      const updatedItem = prevCart.find(
        (item) => item.productId === updateProductId,
      );
      if (updatedItem) {
        updatedItem.quantity = updateProductQuantity;
      }
      return prevCart;
    });
  };

  const updateOrderItem = useMemo(
    () =>
      ({
        ['products']: useUpdateProductOrderItemMutation,
        ['composite-products']: useUpdateCompositeProductOrderItemMutation,
        ['services']: useUpdateServiceOrderItemMutation,
        ['memberships']: useUpdateMembershipOrderItemMutation,
        ['classes']: useUpdateClassOrderItemMutation,
      })[itemType],
    [itemType],
  );

  const [updateItem, { loading, error }] = updateOrderItem({
    onCompleted: (data) => {
      switch (itemType) {
        case 'products':
          setUpdatedActiveItems(
            (data as UpdateProductOrderItemMutation)?.updateProductOrderItem
              ?.data?.attributes?.itemId as string,
            (data as UpdateProductOrderItemMutation)?.updateProductOrderItem
              ?.data?.attributes?.quantity as number,
          );
          break;
        case 'composite-products':
          setUpdatedActiveItems(
            (data as UpdateCompositeProductOrderItemMutation)
              ?.updateCompositeProductOrderItem?.data?.attributes
              ?.itemId as string,
            (data as UpdateCompositeProductOrderItemMutation)
              ?.updateCompositeProductOrderItem?.data?.attributes
              ?.quantity as number,
          );
          break;
        case 'services':
          setUpdatedActiveItems(
            (data as UpdateServiceOrderItemMutation)?.updateServiceOrderItem
              ?.data?.attributes?.itemId as string,
            (data as UpdateServiceOrderItemMutation)?.updateServiceOrderItem
              ?.data?.attributes?.quantity as number,
          );
          break;
        case 'memberships':
          setUpdatedActiveItems(
            (data as UpdateMembershipOrderItemMutation)
              ?.updateMembershipOrderItem?.data?.attributes?.itemId as string,
            (data as UpdateMembershipOrderItemMutation)
              ?.updateMembershipOrderItem?.data?.attributes?.quantity as number,
          );
          break;
        case 'classes':
          setUpdatedActiveItems(
            (data as UpdateClassOrderItemMutation)?.updateClassOrderItem?.data
              ?.attributes?.itemId as string,
            (data as UpdateClassOrderItemMutation)?.updateClassOrderItem?.data
              ?.attributes?.quantity as number,
          );
          break;
        default:
          message.open('error');
      }
    },
    onError: () => message.open('error'),
    refetchQueries: ['orderById'],
  });

  return { updateItem, loading, error };
}
