import { LocalStorageCart, LocalStorageOrder } from '@/types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

const useLocationLocalStorage = () => {
  const { location: locationId } = useParams();
  const [, setLocalStorageLocation] = useLocalStorage('location', '');
  const [localStorageOrder] = useLocalStorage('order', {} as LocalStorageOrder);
  const [localStorageCart, setLocalStorageCart] = useLocalStorage(
    'cart',
    [] as LocalStorageCart,
  );

  useEffect(() => {
    if (locationId) {
      const orderNotEmpty = Object.keys(localStorageOrder).length > 0;
      const cartNotEmpty = localStorageCart.length > 0;

      if (
        (locationId !== localStorageOrder.businessLocation && orderNotEmpty) ||
        (cartNotEmpty && Object.keys(localStorageOrder).length === 0)
      ) {
        setLocalStorageCart([]);
        localStorage.removeItem('order');
        setLocalStorageLocation(locationId);
      } else {
        setLocalStorageLocation(locationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, setLocalStorageLocation]);
};

export default useLocationLocalStorage;
