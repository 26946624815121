import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Button, ButtonProps, Modal, ModalFuncProps } from 'antd';

import classNames from 'classnames';

import styles from './Button.module.scss';

const { confirm } = Modal;

export interface CustomButtonProps extends ButtonProps {
  iconPosition?: 'left' | 'right';
  paddingless?: boolean;
  isCircle?: boolean;
  absoluteHref?: string;
  confirmProps?: ModalFuncProps;
}

export const CustomButton: FC<CustomButtonProps> = (props) => {
  const {
    iconPosition = 'left',
    icon,
    children,
    href,
    absoluteHref,
    paddingless,
    isCircle,
    type,
    className,
    style,
    onClick,
    confirmProps,
    ...otherProps
  } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (confirmProps) {
        confirm(confirmProps);
      }

      if (onClick) {
        onClick(e);
      }
    },
    [onClick, confirmProps],
  );

  const buttonComponent = (
    <Button
      className={classNames(styles.button, className, {
        [styles.buttonIconLeft]: iconPosition === 'left',
        [styles.buttonIconRight]: iconPosition === 'right',
        [styles.paddingless]: paddingless,
        [styles.text]: type === 'text',
        [styles.primary]: type === 'primary',
        [styles.default]: type === 'default',
        [styles.circle]: isCircle,
      })}
      type={type}
      style={{
        ...style,
        padding: paddingless ? 0 : undefined,
      }}
      href={absoluteHref}
      onClick={onClick || confirmProps ? handleClick : undefined}
      {...otherProps}
    >
      {icon}
      {children}
    </Button>
  );

  return href ? <Link to={href}>{buttonComponent}</Link> : buttonComponent;
};
