import { LocalStorageCart } from '@/types';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const useItemsQuantityInCart = (): number => {
  const [localStorageCart] = useLocalStorage('cart', [] as LocalStorageCart);
  const [count, setCount] = useState(localStorageCart.length);
  const authToken = localStorage.getItem('auth_token');
  const isCartEmpty =
    !localStorageCart ||
    localStorageCart.length === 0 ||
    !authToken ||
    authToken === '';

  useEffect(() => {
    if (isCartEmpty) {
      setCount(0);
    } else {
      const total = localStorageCart?.reduce((accum, it) => {
        return accum + it?.quantity;
      }, 0);
      setCount(total);
    }
  }, [isCartEmpty, localStorageCart]);

  return count;
};

export default useItemsQuantityInCart;
