import React, { FC, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './CustomSection.module.scss';

const CustomSection: FC<{
  children: ReactNode;
  sectionClass?: string;
  sectionStyle?: React.CSSProperties;
}> = ({ children, sectionClass, sectionStyle }) => {
  return (
    <section
      className={classNames(styles.pageSection, sectionClass)}
      style={sectionStyle}
    >
      {children}
    </section>
  );
};

export default CustomSection;
