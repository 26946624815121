import styles from '@components/layout/MainLayout/header/Header.module.scss';
import { Form, FormInstance, Input } from 'antd';
import { BaseSyntheticEvent, FC } from 'react';

type SearchFormProps = {
  handleFinish: (value: { searchText: string }) => void;
  handleFormChange: (e: BaseSyntheticEvent) => void;
  form: FormInstance;
};
const SearchForm: FC<SearchFormProps> = ({
  handleFinish,
  handleFormChange,
  form,
}) => {
  return (
    <Form
      onFinish={handleFinish}
      className={styles.searchForm}
      onChange={handleFormChange}
      autoComplete={'off'}
      form={form}
    >
      <Form.Item name={'searchText'} style={{ width: '100%' }}>
        <Input
          placeholder={'Search text'}
          style={{ marginRight: 8 }}
          autoFocus
          maxLength={24}
        />
      </Form.Item>
    </Form>
  );
};

export default SearchForm;
