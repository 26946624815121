import {
  OrderItemTypeMap,
  useUpdateOrderItem,
} from '@hooks/useUpdateOrderItem';
import { useCallback } from 'react';

type UpdateItemInCartProps = {
  itemType: OrderItemTypeMap;
  itemId: string;
  itemPrice: number;
  itemTax: string;
};
export function useUpdateItemInCart({
  itemType,
  itemId,
  itemPrice,
  itemTax,
}: UpdateItemInCartProps) {
  const { updateItem, loading: updateLoading } = useUpdateOrderItem(itemType);

  const handleUpdate = useCallback(
    async (itemQuantity: number) => {
      await updateItem({
        variables: {
          id: itemId,
          input: {
            price: itemPrice,
            quantity: itemQuantity,
            tax: itemTax,
          },
        },
      });
    },
    [updateItem, itemId, itemPrice, itemTax],
  );

  return {
    handleUpdate,
    updateLoading,
  };
}
