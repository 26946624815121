import { Result, Typography } from 'antd';
import { FC } from 'react';

import { CustomButton } from '@/components/ui/button/Button';
import CustomContainer from '@components/layout/MainLayout/custom-container/CustomContainer';
import CustomSection from '@components/layout/MainLayout/custom-section/CustomSection';

import { customerWebsiteRoutes } from '@router/routes';

import useScrollToTop from '@hooks/useScrollToTop';
import { useStorageLocation, useStorageType } from '@hooks/useStorage';
import { ResultStatusType } from 'antd/lib/result';
import { useNavigate } from 'react-router';
import styles from './CheckoutStatusPage.module.scss';

const { shop } = customerWebsiteRoutes;

const { Paragraph, Title } = Typography;

type Props = {
  checkoutStatus: ResultStatusType | undefined;
  checkoutTitle: string;
  checkoutSubtitle?: string;
};

const CheckoutStatusPage: FC<Props> = ({
  checkoutStatus,
  checkoutTitle,
  checkoutSubtitle,
}) => {
  const navigate = useNavigate();
  const locationId = useStorageLocation();
  const type = useStorageType();

  const onBackClick = () => {
    navigate(`${shop}/${locationId}/${type}`);
  };
  useScrollToTop();

  return (
    <CustomSection sectionStyle={{ padding: '80px 0' }}>
      <CustomContainer>
        <Result
          status={checkoutStatus}
          title={<Title level={2}>{checkoutTitle}</Title>}
          subTitle={<Paragraph>{checkoutSubtitle}</Paragraph>}
          className={styles.checkoutStatusWrapper}
          extra={[
            <CustomButton
              key={1}
              type={'primary'}
              size={'large'}
              onClick={onBackClick}
            >
              {'Back to shop page'}
            </CustomButton>,
          ]}
        />
      </CustomContainer>
    </CustomSection>
  );
};

export default CheckoutStatusPage;
