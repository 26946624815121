import { StripeElements } from "@stripe/stripe-js";

export enum DealTransactionStatus {
  OPEN = 'Open',
  PAID = 'Paid',
  CANCELLED = 'Cancelled',
  REFUNDED = 'Refunded',
  RUNNING = 'Running',
  }


export interface EnumConfirmPaymentElementData {
  elements?: StripeElements | undefined;
  clientSecret: string;
  confirmParams: {
    return_url: string;
    payment_method: string | undefined;
  };
  redirect: 'if_required';
}

export enum EnumPaymentStatus {
  Succeeded = 'succeeded',
  Failed = 'failed',
  Pending = 'pending',
}