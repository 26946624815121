import { useStatusMessage } from '@/components/app/StatusMessageContext/statusMessageContext';
import { useGetStripeOnboardingDetailsQuery, useMeQuery, usePaymentStatusQuery } from '@/graphql';
import { customerWebsiteRoutes } from '@/router/routes';
import { LocalStorageCart } from '@/types';
import CheckoutStatusPage from '@pages/website/checkout/checkout-status-page/CheckoutStatusPage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

const CheckoutPendingPage = () => {
  const message = useStatusMessage();
  const [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get('payment_intent');
  const navigate = useNavigate();
  const { checkoutSuccess, checkoutFailed } = customerWebsiteRoutes;
  const [, setLocalCart] = useLocalStorage('cart', [] as LocalStorageCart);

  const { data: me } = useMeQuery();

  const { data: stripeOnboardingData } = useGetStripeOnboardingDetailsQuery({
    variables: {
      input: {
        tenantId: me?.me?.attributes?.tenant?.data?.id,
      },
    },
  });

  const { data: paymentData } = usePaymentStatusQuery({
    variables: {
      input: {
        paymentIntentId: paymentIntentId,
        accountId: stripeOnboardingData?.getStripeOnboardingDetails?.accountId ?? '',
      },
    },
    onError: (error) => {
      message.open('error', error.message);
    },
  });

  useEffect(() => {
    if (paymentData?.paymentStatus?.status === 'succeeded') {
      setLocalCart([]);
      localStorage.removeItem('order');
      navigate(checkoutSuccess);
    } else if (paymentData?.paymentStatus?.status === 'failed') {
      navigate(checkoutFailed);
    }
  }, [paymentData, paymentIntentId, navigate, checkoutSuccess, checkoutFailed, setLocalCart])


  return (
    <CheckoutStatusPage
      checkoutStatus={'warning'}
      checkoutTitle={'Payment pending'}
      checkoutSubtitle={
        'Your payment is pending. Please wait for a few minutes or contact us for further assistance.'
      }
    />
  );
};

export default CheckoutPendingPage;
