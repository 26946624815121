export const formatNumber = (
  value?: number | null,
  additionalOptions?: Intl.NumberFormatOptions,
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...additionalOptions,
  }).format(value || 0);
};

export const formatToCurrency = (value?: number | null): string => {
  return formatNumber(value, { currency: 'USD', style: 'currency' });
};
