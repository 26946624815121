type RouteObject = {
  [key: string]: string | RouteObject;
};

type RouteEntity = string | RouteObject;
export const ROUTES = {
  index: 'home',
  home: 'home',
  shop: 'shop',
  aboutUs: 'about-us',
  blog: 'blog',
  faq: 'faq',
  checkout: 'checkout',
  checkoutSuccess: 'checkout-success',
  checkoutFailed: 'checkout-failed',
  checkoutPending: 'checkout-pending',
  account: 'account',
  notFound: 'not-found',
  privacyPolicy: 'privacy-policy',
  termsAndConditions: 'terms-and-conditions',
  auth: {
    index: 'auth',
    signIn: 'sign-in',
    signUp: 'sign-up',
    forgotPassword: 'forgot-password',
    resetPassword: {
      index: 'reset-password',
      createPassword: 'create-password',
    },
    google: 'google',
    emailConfirmation: 'email-confirmation',
    emailConfirmationSuccess: 'email-confirmation-success',
    registrationSuccess: 'registration-success',
    notConfirmed: 'not-confirmed',
  },
};

const mapRoutesToParent = (
  route: RouteEntity,
  parentPath: string,
): RouteEntity => {
  return Object.entries(route).reduce((acc, [key, value]) => {
    const routePath = `${parentPath}${
      key === 'index' || parentPath === '/' ? '' : '/'
    }`;

    if (typeof value === 'string') {
      return {
        ...acc,
        [key]: `${routePath}${key === 'index' ? '' : value}`,
      };
    }

    return {
      ...acc,
      [key]: mapRoutesToParent(value, `${routePath}${value.index}`),
    };
  }, {});
};

export const customerWebsiteRoutes = mapRoutesToParent(
  ROUTES,
  '/',
) as typeof ROUTES;
