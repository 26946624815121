import { Col, Row, Typography } from 'antd';

const { Text } = Typography;
const CartEmptyPlaceholder = () => {
  return (
    <Row style={{ padding: 40 }} justify={'center'}>
      <Col>
        <Text>{'Your cart is empty'}</Text>
      </Col>
    </Row>
  );
};

export default CartEmptyPlaceholder;
