import { Skeleton } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { customerWebsiteRoutes } from '@router/routes';

import styles from './Logo.module.scss';

interface Props {
  link?: string;
  url?: string;
  hasLink?: boolean;
  logoLoading?: boolean;
}

const Logo: FC<Props> = ({
  link = customerWebsiteRoutes.home,
  url,
  hasLink = true,
  logoLoading,
}) => {
  return (
    <div className={styles.logoWrapper}>
      {logoLoading ? (
        <Skeleton.Avatar active={true} shape={'square'} size={'large'} />
      ) : hasLink ? (
        <Link to={link}>
          <img src={url} alt={'Logo'} className={styles.logo} />
        </Link>
      ) : (
        <img src={url} alt={'Logo'} className={styles.logo} />
      )}
    </div>
  );
};

export { Logo };
