import { useOrdersHistoryQuery } from '@/graphql';

export function useOrderData(id: Maybe<string> | null) {
  const { data, loading: orderLoading } = useOrdersHistoryQuery({
    variables: {
      filters: {
        id: {
          eq: String(id),
        },
      },
    },
  });

  const order = data?.orders?.data?.[0];
  const orderContact = order?.attributes?.contact?.data as ContactMinFragment;
  const orderCompany = order?.attributes?.company?.data as CompanyMinFragment;

  const orderProductsBuy =
    order?.attributes?.products?.data?.filter(
      (item) => item?.attributes?.purchaseType === 'buy',
    ) ?? [];
  const orderProductsRent =
    order?.attributes?.products?.data?.filter(
      (item) => item?.attributes?.purchaseType === 'rent',
    ) ?? [];
  const orderCompositeProducts =
    order?.attributes?.compositeProducts?.data ?? [];
  const orderClasses = order?.attributes?.classes?.data ?? [];
  const orderServices = order?.attributes?.services?.data ?? [];
  const orderMemberships = order?.attributes?.memberships?.data ?? [];

  const hasOrderItemsBought = !(
    !orderProductsBuy.length &&
    !orderCompositeProducts.length &&
    !orderServices.length &&
    !orderClasses.length &&
    !orderMemberships.length
  );

  return {
    order,
    orderContact,
    orderCompany,
    orderProductsBuy,
    orderClasses,
    orderProductsRent,
    orderServices,
    orderMemberships,
    orderCompositeProducts,
    orderLoading,
    hasOrderItemsBought,
  };
}
