import { Icon } from '@assets/icon';
import { CustomButton } from '@ui/button/Button';

import { useCreateDealTransactionCustomerMutation, useMeQuery, useOrderByIdWithQuantitiesLazyQuery } from '@/graphql';
import { LocalStorageOrder } from '@/types';
import { formatToCurrency } from '@helpers/formatter';
import useItemsQuantityInCart from '@hooks/itemQuantityInCart';
import { useBreakpoint } from '@hooks/useBreakpoint';
import CartDrawerClass from '@pages/website/cart/cart-drawer-item/CartDrawerClass';
import CartDrawerCompositeProduct from '@pages/website/cart/cart-drawer-item/CartDrawerCompositeProduct';
import CartDrawerMembership from '@pages/website/cart/cart-drawer-item/CartDrawerMembership';
import CartDrawerProduct from '@pages/website/cart/cart-drawer-item/CartDrawerProduct';
import CartDrawerService from '@pages/website/cart/cart-drawer-item/CartDrawerService';
import CartEmptyPlaceholder from '@pages/website/cart/cart-empty-placeholder/CartEmptyPlaceholder';
import { customerWebsiteRoutes } from '@router/routes';
import { Button, Drawer, Typography } from 'antd';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import styles from './CartDrawer.module.scss';
import dayjs from 'dayjs';
import { DealTransactionStatus } from '../../checkout/type';
import { getEntityIdWithRegex } from '@pages/website/cart/cart-drawer/helpers/getOrderId';

const { checkout } = customerWebsiteRoutes;

const { Title, Text } = Typography;
type Props = {
  isDrawerOpen: boolean;
  toggleShowDrawer: () => void;
};
const CartDrawer: FC<Props> = ({ isDrawerOpen, toggleShowDrawer }) => {
  const { sm, lg } = useBreakpoint();
  const navigate = useNavigate();
  const [localStorageOrder] = useLocalStorage('order', {} as LocalStorageOrder);
  const { data: dataMe, loading } = useMeQuery();

  const [fetchOrder, { data: orderData, refetch }] =
    useOrderByIdWithQuantitiesLazyQuery();

  const [createDealTransactionCustomer] = useCreateDealTransactionCustomerMutation();

  useEffect(() => {
    if (isDrawerOpen) {
      fetchOrder({
        variables: {
          orderId: localStorageOrder.orderId,
        },
      });
    }
  }, [isDrawerOpen, fetchOrder, localStorageOrder]);

  useEffect(() => {
    if (isDrawerOpen) {
      refetch({ orderId: localStorageOrder.orderId });
    }
  }, [isDrawerOpen, localStorageOrder.orderId, refetch]);

  const products = orderData?.orders?.data?.[0]?.attributes?.products
    ?.data as ProductOrderItemEntity[];
  const compositeProducts = orderData?.orders?.data?.[0]?.attributes
    ?.compositeProducts?.data as CompositeProductOrderItemEntity[];
  const services = orderData?.orders?.data?.[0]?.attributes?.services
    ?.data as ServiceOrderItemEntity[];
  const memberships = orderData?.orders?.data?.[0]?.attributes?.memberships
    ?.data as MembershipOrderItemEntity[];
  const classes = orderData?.orders?.data?.[0]?.attributes?.classes
    ?.data as ClassOrderItemEntity[];

  const totalProductsCount = useItemsQuantityInCart();

  const handleCheckout = () => {
    if (orderData?.orders?.data[0]?.attributes?.total) {
        createDealTransactionCustomer({
          variables: {
            input: {
              chartAccount: '2',
              dealTransactionId: getEntityIdWithRegex(),
              dueDate: dayjs().format('YYYY-MM-DD HH:mm:ss') ,
              paid: 0,
              repetitive: 'once',
              sellingOrder: orderData?.orders?.data[0]?.id ?? 'string',
              status: DealTransactionStatus.RUNNING,
              summary: orderData?.orders?.data[0]?.attributes?.total ?? 0,
              tenantId: dataMe?.me?.attributes?.tenant?.data?.id ?? 'string',
            }
          }
        })
    }

    toggleShowDrawer();
    navigate(checkout);
  };

  return (
    <Drawer
      placement={'right'}
      closable={false}
      open={isDrawerOpen}
      width={sm ? 500 : '100%'}
      style={{ padding: lg ? '16px 8px' : 0 }}
      onClose={toggleShowDrawer}
    >
      <div className={styles.body}>
        <div className={styles.header}>
          <Title level={3}>
            {totalProductsCount
              ? `Your Cart (${totalProductsCount})`
              : 'Your Cart'}
          </Title>
          <CustomButton type={'text'} onClick={toggleShowDrawer}>
            <Icon type={'close'} />
          </CustomButton>
        </div>
        <div className={styles.content}>
          <>
            {products?.map((product, index) => (
              <CartDrawerProduct key={index} data={product} />
            ))}
            {compositeProducts?.map((compositeProduct, index) => (
              <CartDrawerCompositeProduct key={index} data={compositeProduct} />
            ))}
            {services?.map((service, index) => (
              <CartDrawerService key={index} data={service} />
            ))}
            {memberships?.map((membership, index) => (
              <CartDrawerMembership key={index} data={membership} />
            ))}
            {classes?.map((classItem, index) => (
              <CartDrawerClass key={index} data={classItem} />
            ))}
          </>
          {!totalProductsCount && <CartEmptyPlaceholder />}
        </div>
        <div className={styles.footer}>
          <div className={styles.footerTextBlock}>
            <Text className={styles.footerTextTitle}>{'subtotal:'}</Text>
            <Text strong>
              {formatToCurrency(
                orderData?.orders?.data?.[0]?.attributes?.subTotal,
              )}
            </Text>
          </div>
          <Button
            block
            type={'primary'}
            size={'large'}
            onClick={handleCheckout}
          >
            {'Checkout'}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CartDrawer;
