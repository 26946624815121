import { FC, useEffect, useState } from 'react';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';

import styles from './CustomInputNumber.module.scss';

type Props = {
  initValue: number;
  minValue: number;
  maxValue: number;
  onChange: (value: number) => void;
};

const CustomInputNumber: FC<Props> = ({
  initValue,
  minValue,
  maxValue,
  onChange,
}) => {
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const isMinusDisabled = !value || value <= minValue;
  const isPlusDisabled = !value || value >= maxValue;

  const handleMinusClick = () => {
    if (value && value > minValue) {
      setValue(value - 1);
      onChange(value - 1);
    }
  };

  const handlePlusClick = () => {
    if (value && value < maxValue) {
      setValue(value + 1);
      onChange(value + 1);
    }
  };

  const handleInputChange = (inputValue: number | null) => {
    setValue(inputValue as number);
    onChange(inputValue as number);
  };

  return (
    <InputNumber
      className={styles.customInputNumber}
      min={minValue}
      max={maxValue}
      value={value}
      onChange={handleInputChange}
      controls={false}
      formatter={(inputValue) => `${inputValue}`}
      parser={(inputText) => {
        const parsedValue = parseInt(inputText || '0', 10);
        return isNaN(parsedValue) ? 1 : parsedValue;
      }}
      addonBefore={
        <Button
          className={styles.customInputNumberControls}
          icon={<MinusOutlined />}
          onClick={handleMinusClick}
          disabled={isMinusDisabled}
        />
      }
      addonAfter={
        <Button
          className={styles.customInputNumberControls}
          icon={<PlusOutlined />}
          onClick={handlePlusClick}
          disabled={isPlusDisabled}
        />
      }
    />
  );
};

export default CustomInputNumber;
