import { useMemo } from 'react';

import {
  useDeleteClassOrderItemMutation,
  useDeleteCompositeProductOrderItemMutation,
  useDeleteMembershipOrderItemMutation,
  useDeleteProductOrderItemMutation,
  useDeleteServiceOrderItemMutation,
} from '@/graphql';

import { LocalStorageCart } from '@/types';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useLocalStorage } from 'usehooks-ts';

export type OrderItemTypeMap =
  | 'products'
  | 'composite-products'
  | 'services'
  | 'memberships'
  | 'classes';

export function useDeleteOrderItem<T extends OrderItemTypeMap>(itemType: T) {
  const [, setLocalStorageCart] = useLocalStorage(
    'cart',
    [] as LocalStorageCart,
  );
  const message = useStatusMessage();

  const setUpdatedActiveItems = (deleteProductId: string) => {
    setLocalStorageCart((prevCart) =>
      prevCart.filter((item) => item.productId !== deleteProductId),
    );
  };

  const deleteOrderItem = useMemo(
    () =>
      ({
        ['products']: useDeleteProductOrderItemMutation,
        ['composite-products']: useDeleteCompositeProductOrderItemMutation,
        ['services']: useDeleteServiceOrderItemMutation,
        ['memberships']: useDeleteMembershipOrderItemMutation,
        ['classes']: useDeleteClassOrderItemMutation,
      })[itemType],
    [itemType],
  );

  const [deleteItem, { loading, error }] = deleteOrderItem({
    onCompleted: (data) => {
      message.open('success', 'Order Item Removed');
      switch (itemType) {
        case 'products':
          setUpdatedActiveItems(
            (data as DeleteProductOrderItemMutation)?.deleteProductOrderItem
              ?.data?.attributes?.itemId as string,
          );
          break;
        case 'composite-products':
          setUpdatedActiveItems(
            (data as DeleteCompositeProductOrderItemMutation)
              ?.deleteCompositeProductOrderItem?.data?.attributes
              ?.itemId as string,
          );
          break;
        case 'services':
          setUpdatedActiveItems(
            (data as DeleteServiceOrderItemMutation)?.deleteServiceOrderItem
              ?.data?.attributes?.itemId as string,
          );
          break;
        case 'memberships':
          setUpdatedActiveItems(
            (data as DeleteMembershipOrderItemMutation)
              ?.deleteMembershipOrderItem?.data?.attributes?.itemId as string,
          );
          break;
        case 'classes':
          setUpdatedActiveItems(
            (data as DeleteClassOrderItemMutation)?.deleteClassOrderItem?.data
              ?.attributes?.itemId as string,
          );
          break;
        default:
          message.open('error');
      }
    },
    onError: () => message.open('error'),
    refetchQueries: ['orderById'],
  });

  return { deleteItem, loading, error };
}
