import { FC } from 'react';

import { Button, Col, Row, Skeleton } from 'antd';

import { Icon, IconType } from '@assets/icon';

import { useBreakpoint } from '@hooks/useBreakpoint';
import styles from '../Footer.module.scss';

type Props = {
  followUsLinksArray: ComponentUiLink[];
  linksLoading?: boolean;
};

const array = Array.from({ length: 3 });

const FooterFollowLinks: FC<Props> = ({ followUsLinksArray, linksLoading }) => {
  const { md } = useBreakpoint();

  return (
    <Row className={styles.iconsRow} gutter={16} justify={md ? 'end' : 'start'}>
      {linksLoading
        ? array.map((_, index) => (
            <Col key={index}>
              <Skeleton.Avatar active={true} shape={'square'} size={'small'} />
            </Col>
          ))
        : followUsLinksArray &&
          followUsLinksArray?.map((it) => (
            <Col key={it?.id}>
              <Button
                type={'link'}
                className={styles.footerIconButton}
                href={`https://${it?.url}` || ''}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <Icon
                  type={it?.title?.toLowerCase() as IconType}
                  exactSize={24}
                />
              </Button>
            </Col>
          ))}
    </Row>
  );
};

export default FooterFollowLinks;
