import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLogin } from '@hooks/auth/useLogin';

export const GoogleAuthCallback = () => {
  const [searchParams] = useSearchParams();

  const [login] = useLogin();

  useEffect(() => {
    const accessToken = searchParams.get('access_token');

    if (accessToken) {
      login({
        variables: {
          input: {
            provider: 'google',
            identifier: accessToken,
            password: '',
          },
        },
      });
    }
  }, [searchParams, login]);

  return null;
};
