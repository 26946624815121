import CheckoutStatusPage from '@pages/website/checkout/checkout-status-page/CheckoutStatusPage';

const CheckoutSuccessPage = () => {
  return (
    <CheckoutStatusPage
      checkoutStatus={'success'}
      checkoutTitle={'Order created successfully!'}
      checkoutSubtitle={
        'You can check you order history on you personal account page.'
      }
    />
  );
};

export default CheckoutSuccessPage;
