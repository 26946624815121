import React, { FC, useEffect, useMemo, useState } from 'react';

import type { MenuProps } from 'antd';
import { Collapse, Dropdown, Typography } from 'antd';

import { useBreakpoint } from '@hooks/useBreakpoint';

import { Icon } from '@assets/icon';
import LocationSelector from '@ui/shop-selector/locations-list/LocationsList';

import { useBusinessLocationsQuery } from '@/graphql';
import { LocalStorageCart } from '@/types';
import { truncateString } from '@helpers/truncate';
import { customerWebsiteRoutes } from '@router/routes';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import styles from './ShopSelector.module.scss';

const { Paragraph } = Typography;
const { shop } = customerWebsiteRoutes;

const panelStyle: React.CSSProperties = {
  background: 'none',
  border: 'none',
};

type Props = {
  onCloseDrawer?: () => void;
  showModal: (location: string) => void;
};

const ShopSelector: FC<Props> = ({ onCloseDrawer, showModal }) => {
  const navigate = useNavigate();
  const { lg } = useBreakpoint();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const { data: locationsData } = useBusinessLocationsQuery({
    variables: {
      sort: ['name:ASC'],
      filters: {
        type: { eq: 'store' },
      },
    },
  });
  const businessLocations = locationsData?.businessLocations?.data;

  const [localStorageLocation, setLocalStorageLocation] = useLocalStorage(
    'location',
    '',
  );
  const [currentLocation, setCurrentLocation] = useState(localStorageLocation);
  const [, setLocalStorageCart] = useLocalStorage(
    'cart',
    [] as LocalStorageCart,
  );

  useEffect(() => {
    if (localStorageLocation) {
      setCurrentLocation(localStorageLocation);
    } else if (businessLocations && businessLocations.length > 0) {
      setCurrentLocation(businessLocations[0]?.id as string);
      setLocalStorageLocation(businessLocations[0]?.id as string);

      setLocalStorageCart([]);
      localStorage.removeItem('order');

      const isShopPage = location.pathname.includes('shop');
      const isCheckoutPage = location.pathname.includes('checkout');
      if (isShopPage || isCheckoutPage) {
        navigate(`${shop}/${businessLocations[0]?.id}/products`);
      }
    }
  }, [
    businessLocations,
    localStorageLocation,
    setLocalStorageLocation,
    setLocalStorageCart,
    navigate,
  ]);

  const handleShopSelectorDropdownChange = (visible: boolean) =>
    setDropdownVisible(visible);

  const locationName =
    businessLocations?.find((location) => location.id === currentLocation)
      ?.attributes?.name || 'Select store';

  const items: MenuProps['items'] = useMemo(() => {
    return [
      {
        label: (
          <LocationSelector
            businessLocations={businessLocations as BusinessLocationFragment[]}
            showModal={showModal}
          />
        ),
        key: '0',
      },
    ];
  }, [businessLocations, showModal]);

  const getItems = useMemo(() => {
    return [
      {
        key: '1',
        label: (
          <Paragraph className={styles.shopSelectorCollapseTitle}>
            {locationName}
          </Paragraph>
        ),
        children: (
          <LocationSelector
            businessLocations={businessLocations as BusinessLocationFragment[]}
            onCloseDrawer={onCloseDrawer}
            showModal={showModal}
          />
        ),
        style: panelStyle,
      },
    ];
  }, [locationName, businessLocations, onCloseDrawer, showModal]);

  return (
    <>
      {lg ? (
        <div className={styles.dropdownItem}>
          <Dropdown
            menu={{ items }}
            onOpenChange={handleShopSelectorDropdownChange}
            overlayClassName={styles.menuDropdownWrapper}
          >
            <Paragraph
              style={{ color: dropdownVisible ? '#1f2933' : '' }}
              className={styles.menuItemWithDropdown}
            >
              {truncateString(locationName, 14)}
              <Icon type={dropdownVisible ? 'chevron-up' : 'chevron-down'} />
            </Paragraph>
          </Dropdown>
        </div>
      ) : (
        <Collapse
          bordered={false}
          defaultActiveKey={['0']}
          expandIconPosition={'end'}
          className={styles.shopSelectorCollapse}
          expandIcon={({ isActive }) => {
            return (
              <Icon
                type={isActive ? 'chevron-up' : 'chevron-down'}
                exactSize={24}
              />
            );
          }}
          style={{ width: '100%', background: 'none' }}
          items={getItems}
        />
      )}
    </>
  );
};

export default ShopSelector;
